<template>
    <div id="hero-section">
        <div id="hero-content" class="container">
            <div id="col1">
                <img src="../assets/mainLogo.png" alt="s">
            </div>
            <div id="col2">
                <div id="hero-text">
                    <p style="line-height: 50px;">25 - 27. April, 2025.</p>
                    <!--<p>Mašinski fakultet</p>
                    <p :style="{fontSize: !tabletView ? '30px' : '20px'}">Univerziteta u Beogradu</p>-->
                    
                </div>


                <PrijaviSe :mobile="isMobile" style="width: 90%; margin-top: 15px; font-size: 35px;" >Prijavi se</PrijaviSe>
<!--<p :style="{fontSize: !tabletView ? '20px' : '13px'}" style="font-weight: 600;">Prijave su otvorene do 14. maja</p>  -->
            </div>
        </div>
    </div>
    <FluidCol style="background-color: #38EF7D ;">
        <template v-if="!tabletView" v-slot:left>
            <img  style="width: 100%; height: 100%;" src="../assets/oNama_bg.png" alt="">
        </template>
        <template v-slot:right>
            <div class="textCol">
                <h2>O projektu</h2>
                <p>„Soft Skills Academy – Unapredi svoje sposobnosti!“ je besplatan seminar ličnih i profesionalnih veština namenjen studentima svih fakulteta u Beogradu. Organizuje se šesnaesti put zaredom od strane Evropskog udruženja studenata elektrotehnike – EESTEC. Održaće se uživo od 4. do 6. aprila 2025. godine.</p>
                <p>Seminar je besplatan i dostupan svim zainteresovanim studentima svih fakulteta u Beogradu, a najboljih 35 kandidata biće izabrano da učestvuju na treninzima.</p>
                <!-- <PrijaviSe style="margin-top: 50px;">Saznaj više</PrijaviSe> -->
                <router-link class="mobileLinks" :to="{ name: 'oProjektu' }">
                    <button id="saznajVise">
                        Saznaj više
                    </button>
                </router-link>

            </div>
        </template>
    </FluidCol>
    <FluidCol style="background-color: #11998E;">
        <template v-slot:left>
            <div class="textCol">
                <h2>Treninzi mekih veština za sve studente</h2>
                <p>Seminar je besplatan i dostupan svim zainteresovanim studentima svih fakulteta u Beogradu, a najboljih 35 kandidata biće izabrano da učestvuju na treninzima.</p>
                <PrijaviSe :mobile="isMobile" style="margin: 0px 0px 30px 0px;">Prijavi se</PrijaviSe>
            </div>
        </template>
        <template  v-if="!tabletView" v-slot:right>
            <img style="width: 100%; height: 100%;" src="../assets/treninzi.jpg" alt="">
        </template>
    </FluidCol>
</template>



<script>
import PrijaviSe from '../components/PrijaviSe.vue';
import FluidCol from "../components/FluidColumn.vue";

export default {
    components: { PrijaviSe, FluidCol},
    data(){
        return{
            tabletView: false,
            isMobile: false
        }
    },
    methods:{
        handleView(){
            this.tabletView = window.innerWidth <= 991
            this.isMobile = window.innerWidth <= 478
        }
    },
    created(){
       this.handleView() // required when first is loaded 
    },
    mounted(){
        window.addEventListener('resize', this.handleView)
    }
}
</script>


<style>
#hero-section {
    background-image: url("../assets/home_hero_bg.png");
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}

#hero-content {
    padding-top: 120px;
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

#col1 {
    padding-right: 80px;
}

#col2 {
    padding-left: 80px;
}

#hero-text {
    text-align: justify;
    text-align-last: justify;
}

/* #hero-text:after {
      content: "";
  display: inline-block;
  width: 100%;
} */

#hero-content p {
    font-size: 40px;
    line-height: 35px;
    margin: 0px;
    font-weight: 900;
}

.textCol {
    padding: 0px 60px 0px 60px;
}

.textCol p {
    margin-bottom: 40px;
}

#saznajVise {
    background-color: #1d1d1d; 
    color: white;
    font-size: 25px; 
    font-weight: bold; 
    padding: 12px 25px; 
    border-radius: 50px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 30px;
}

#saznajVise:hover {
    transform: translate(-2px, -3px);
    box-shadow: 1px 2px black;
}


@media (max-width: 992px) {

    #col1 {
        padding: 0px;
    }

    #col1 img {
        height: 300px;
    }
    
    #col2 {
        padding: 0px;
        margin: auto;
    }

    .textCol {
        margin: auto;
    }


}


@media (max-width: 768px) {
    #hero-content{
        padding-top: 30px;
        padding-bottom: 30px;
        flex-direction: column;
    }

    #col1 img {
        height: 280px;
    }

    .textCol p {
    margin-bottom: 15px;
    }   

    #hero-text {
        text-align: unset;
        text-align-last: unset;
    }

    #col2 p{
        font-size: 20px;
        line-height: 20px;
    }

    #saznajVise{
        font-size: 15px;
    }

    .textCol {
    padding: 0px 20px 0px 20px;
    }

}



</style>